const VenueIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-3.5 h-3.5 fill-current'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
    >
      <path
        fill='#fff'
        d='M9.74 5.186a.274.274 0 01.52 0l.555 1.5a.28.28 0 00.244.185l1.535.09c.254.014.357.348.16.516l-1.191 1.016a.3.3 0 00-.093.3l.393 1.554c.065.258-.207.464-.42.32l-1.292-.871a.268.268 0 00-.302 0l-1.291.87c-.214.145-.486-.061-.42-.319l.392-1.554a.3.3 0 00-.093-.3L7.246 7.477c-.197-.168-.094-.502.16-.517l1.535-.09a.28.28 0 00.244-.185l.555-1.499z'
      />
      <rect
        width='11.429'
        height='1.429'
        x='4.286'
        y='17.143'
        fill='#fff'
        rx='0.714'
      />
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M10 12.948c3.323 0 6.052.751 7.347 1.183V4.101C14.221 2.87 11.623 2.869 10 2.869c-1.623 0-4.221.001-7.347 1.232v6.24a.612.612 0 01-1.224 0V3.79c0-.426.229-.809.573-.95 3.437-1.412 6.3-1.412 7.991-1.412h.014c1.691 0 4.555 0 7.991 1.412.344.141.573.524.573.95V14.7c0 .696-.577 1.17-1.124.969-.925-.34-3.816-1.28-7.447-1.28-3.63 0-6.522.94-7.447 1.28-.547.2-1.124-.273-1.124-.97v-1.943a.612.612 0 011.224 0v1.376A23.867 23.867 0 0110 12.948z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export { VenueIcon };
