import { type User } from '../../types';

export enum MenuKey {
  Account = 'account',
  VideoAudio = 'video-audio',
  MyVenue = 'my-venue',
  GameSessions = 'game-sessions',
  OrgMembers = 'org-members',
  OrgSettings = 'org-settings',
  HostConfiguration = 'host-configuration',
  CohostCongifuration = 'cohost-configuration',
  LiteMode = 'lite-mode',
  Privacy = 'privacy',
  Language = 'language',
  Notifications = 'notifications',
  Billing = 'billing',
}

export interface MenuProps {
  user: User;
}

export type Menu = {
  key: MenuKey;
  title: string;
  disableTitleInBody?: boolean;
  icon: JSX.Element;
  content: (props: MenuProps) => JSX.Element | null;
};
